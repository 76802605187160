<template>
  <div class="picture-display bg-dark">
    <!-- <img src="@/assets/images/display-bg-video.jpg" class="display-bg" /> -->
    <el-image
      class="display-bg"
      @contextmenu="handleMouse"
      :src="banner_url"
      :fit="'cover'"
    ></el-image>
    <div class="container">
      <search
        @change="searchPicture"
        :type="'video'"
        @select="selectType"
      ></search>
      <div class="keyword">
        <span
          v-for="item in searchtaginfo"
          :key="item.id"
          @click="handleSearch(item.tagname)"
          >{{ item.tagname }}</span
        >
      </div>
      <div class="recommend" v-for="item in blockList" :key="item.id">
        <p class="title">
          <span class="left"
            ><i></i><span class="name">{{ item.name }}</span></span
          >
          <span v-if="item.type !== 2" class="more" @click="moreVideo()"
            >查看更多>></span
          >
        </p>
        <div v-if="item.type == 1" class="block-1">
          <div class="outside-box grid-content-first">
            <div
              class="grid-content"
              @mouseenter="mouseenter(item.blockinfo[0].id)"
              @mouseleave="mouseleave(item.blockinfo[0].id)"
            >
              <el-image
                :src="item.blockinfo[0].image"
                @contextmenu="handleMouse"
                :fit="'cover'"
                class="grid-img"
                :class="{ 'current-image': item.blockinfo[0].id === current }"
              ></el-image>
              <div
                class="sample-player-box"
                @click="handleDetail(item.blockinfo[0])"
              >
                <div class="info-block right-top">
                  {{ item.blockinfo[0].resourceinfo.quality }}
                </div>
                <div class="info-block left-top">
                  {{ item.blockinfo[0].resourceinfo.duration }}
                </div>
                <div class="video-player">
                  <video
                    @contextmenu="handleMouse"
                    :src="item.blockinfo[0].url"
                    :autoplay="true"
                    loop="loop"
                    class="video"
                    v-if="item.blockinfo[0].id === current"
                  >
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
              </div>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">
                    {{ item.blockinfo[0].resourceinfo.title }}
                  </p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="
                        handleCollection(item.blockinfo[0].resourceinfo.id)
                      "
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(item.blockinfo[0])"
                    />
                    <img
                      v-if="item.blockinfo[0].resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="
                        handleLike(
                          item.blockinfo[0].resourceinfo.id,
                          item.blockinfo
                        )
                      "
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="
                        handleLike(
                          item.blockinfo[0].resourceinfo.id,
                          item.blockinfo
                        )
                      "
                    />
                    <span>{{ item.blockinfo[0].resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="outside-box box-right">
            <template v-for="(m, index) in item.blockinfo">
              <div
                class="grid-content"
                v-if="index > 0 && index < 5"
                :key="index"
                @mouseenter="mouseenter(m.id)"
                @mouseleave="mouseleave(m.id)"
              >
                <el-image
                  :src="m.image"
                  @contextmenu="handleMouse"
                  :fit="'cover'"
                  class="grid-img"
                  :class="{ 'current-image': m.id === current }"
                ></el-image>
                <div class="sample-player-box" @click="handleDetail(m)">
                  <div class="info-block right-top">
                    {{ m.resourceinfo.quality }}
                  </div>
                  <div class="info-block left-top">
                    {{ m.resourceinfo.duration }}
                  </div>
                  <div class="video-player">
                    <video
                      @contextmenu="handleMouse"
                      :src="m.url"
                      :autoplay="true"
                      loop="loop"
                      class="video"
                      v-if="m.id === current"
                    >
                      您的浏览器不支持 video 标签。
                    </video>
                  </div>
                </div>
                <div class="grid-text">
                  <div class="grid-oper">
                    <p class="name">
                      {{ m.resourceinfo.title }}
                    </p>
                    <div>
                      <img
                        src="@/assets/images/collection-1.png"
                        @click="handleCollection(m.resourceinfo.id)"
                      />
                      <img
                        src="@/assets/images/forward-1.png"
                        @click="handleShare(m)"
                      />
                      <img
                        v-if="m.resourceinfo.is_zan"
                        src="@/assets/images/like-fill.png"
                        @click="handleLike(m.resourceinfo.id, item.blockinfo)"
                      />
                      <img
                        v-else
                        src="@/assets/images/like-1.png"
                        @click="handleLike(m.resourceinfo.id, item.blockinfo)"
                      />
                      <span>{{ m.resourceinfo.fabulous }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <el-row v-if="item.type == 2">
          <div class="carousel-box">
            <div class="leftArrow">
              <img
                src="@/assets/images/arrow-left-gray.png"
                v-if="currentPage"
                @click="arrowClick('left')"
              />
              <img v-else src="@/assets/images/arrow-left-gray.png" />
            </div>
            <el-carousel
              indicator-position="outside"
              :autoplay="false"
              :loop="false"
              arrow="never"
              ref="cardShow"
            >
              <el-carousel-item v-for="(child, index) in carousel" :key="index">
                <div
                  class="periodical-image"
                  v-for="m in child"
                  @click="handleSearch(m.name)"
                  :key="m.id"
                >
                  <div class="image-title">
                    <div class="text">{{ m.name }}</div>
                    <div
                      class="line"
                      :class="{ 'low-line': m.name.length > 2 }"
                    ></div>
                  </div>
                  <el-image
                    :src="m.showimage"
                    @contextmenu="handleMouse"
                    fit="fill"
                    style="height:390px"
                  ></el-image>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="rightArrow">
              <img
                src="@/assets/images/arrow-right-gray.png"
                @click="arrowClick('right')"
                v-if="carousel.length !== currentPage + 1"
              />
              <img v-else src="@/assets/images/arrow-right-gray.png" />
            </div>
          </div>
        </el-row>
        <el-row v-if="item.type == 3" :gutter="14">
          <el-col
            :span="6"
            v-for="child in item.blockinfo"
            :key="child.id"
            class="grid-col"
            ><div
              class="grid-content"
              @mouseenter="mouseenter(child.id)"
              @mouseleave="mouseleave(child.id)"
            >
              <el-image
                style="height: 176px;min-width: 100%"
                @contextmenu="handleMouse"
                :src="child.image"
                :fit="'cover'"
                class="grid-img"
                :class="{ 'current-image': child.id === current }"
              ></el-image>
              <div class="sample-player-box" @click="handleDetail(child)">
                <div class="info-block right-top">
                  {{ child.resourceinfo.quality }}
                </div>
                <div class="info-block left-top">
                  {{ child.resourceinfo.duration }}
                </div>
                <div class="video-player">
                  <video
                    @contextmenu="handleMouse"
                    :src="child.url"
                    :autoplay="true"
                    loop="loop"
                    class="video"
                    v-if="child.id === current"
                  >
                    您的浏览器不支持 video 标签。
                  </video>
                </div>
              </div>
              <div class="grid-text">
                <div class="grid-oper">
                  <p class="name">{{ child.resourceinfo.title }}</p>
                  <div>
                    <img
                      src="@/assets/images/collection-1.png"
                      @click="handleCollection(child.resourceinfo.id)"
                    />
                    <img
                      src="@/assets/images/forward-1.png"
                      @click="handleShare(child)"
                    />
                    <img
                      v-if="child.resourceinfo.is_zan"
                      src="@/assets/images/like-fill.png"
                      @click="handleLike(child.resourceinfo.id, item.blockinfo)"
                    />
                    <img
                      v-else
                      src="@/assets/images/like-1.png"
                      @click="handleLike(child.resourceinfo.id, item.blockinfo)"
                    />
                    <span>{{ child.resourceinfo.fabulous }}</span>
                  </div>
                </div>
              </div>
            </div></el-col
          >
        </el-row>
      </div>
      <!--分享组件-->
      <poster ref="Poster"></poster>
    </div>
  </div>
</template>

<script>
import search from "../../../components/search/index";
import poster from "@/components/sharePoster/index.vue";
import toLogin from "@/mixins/toLogin";
export default {
  name: "index",
  components: { search, poster },
  mixins: [toLogin],
  data() {
    return {
      user_id: "",
      blockList: [], //分类列表
      banner_url: require("@/assets/images/display-bg-video.jpg"),
      carousel: [],
      searchType: "video",
      current: "",
      searchtaginfo: [],
      currentPage: 0
    };
  },
  async mounted() {
    const user_id = localStorage.getItem("user_id");
    this.user_id = user_id;
    const { data } = await this.$api.getblockList({
      page_id: 2,
      user_id: user_id
    });
    if (data.code === 1) {
      this.blockList = data.data.blockinfos;
      this.searchtaginfo = data.data.searchtaginfo;
      //banner_url如需配成从后台获取打开此处注释
      // this.banner_url = data.data.bannerlist[0]?.banner_url
      //   ? data.data.bannerlist[0]?.banner_url
      //   : require("@/assets/images/display-bg-video.jpg");
      const blockinfo = this.blockList.filter(i => i.type === 2);
      if (blockinfo.length > 0) {
        const content = blockinfo[0].categorylist;
        console.log(999, content);
        const length = content.length;
        const page = Math.floor(length / 6);
        this.carousel = [];
        for (var i = 0; i < page; i++) {
          this.carousel.push(content.slice(i * 6, i * 6 + 6));
        }
        console.log(this.carousel, "this.carousel");
      }
    }
  },
  methods: {
    arrowClick(val) {
      if (val === "right") {
        this.currentPage++;
        this.$refs.cardShow[0].next();
      } else {
        this.currentPage--;
        this.$refs.cardShow[0].prev();
      }
    },
    handleMouse(e) {
      e.preventDefault();
    },
    selectType(type) {
      this.searchType = type;
    },
    handleSearch(tagname) {
      console.log(this.searchType, "this.searchType");
      if (this.searchType == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
      if (this.searchType == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type: this.searchType, text: tagname }
        });
      }
    },
    mouseenter(id) {
      this.current = id;
    },
    mouseleave() {
      this.current = "";
    },
    searchPicture(val) {
      const { text, type } = val;

      if (type == "picture") {
        this.$router.push({
          name: "pictureSearch",
          query: { type, text }
        });
      }
      if (type == "video") {
        this.$router.push({
          name: "videoSearch",
          query: { type, text }
        });
      }
    },
    handleDetail(item) {
      this.$router.push({
        name: "videoPreview",
        query: { id: item.resource_id }
      });
    },
    async handleCollection(id) {
      console.log(123, id);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postShouCang({
        resource_id: id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
      }
    },
    async handleShare(info) {
      console.log(info);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const qrContent =
        window.location.origin + "#/video/preview?id=" + info.resource_id + "";
      const posterInfo = {
        nickname: info.resourceinfo.nickname,
        headimage: info.resourceinfo.headimage,
        user_description: info.resourceinfo.user_description,
        url: info.image,
        title: info.resourceinfo.title,
        description: info.resourceinfo.description,
        resource_id: info.resource_id,
        content: qrContent
      };
      this.$refs["Poster"].show(posterInfo);
      const { data } = await this.$api.postZhuanFa({
        resource_id: info.id,
        user_id: this.user_id
      });
      if (data.code === 1) {
        console.log(data.msg);
      }
    },
    async handleLike(id, list) {
      console.log(789, id);
      //判断是否登录
      if (!this.navigatorToLogin()) {
        return;
      }
      const { data } = await this.$api.postClickZan({
        resource_id: id,
        user_id: this.user_id
      });
      console.log(list, "list");
      const resource = list.filter(i => i.resource_id === id)[0];
      console.log(resource, "resource");
      if (data.code === 1) {
        this.$message({
          message: data.msg,
          type: "success"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous + 1;
      } else {
        this.$message({
          message: data.msg,
          type: "warning"
        });
        resource.resourceinfo.fabulous = resource.resourceinfo.fabulous - 1;
      }
      resource.resourceinfo.is_zan = !resource.resourceinfo.is_zan;
    },
    moreVideo() {
      this.$router.push({ name: "videoSearch" });
    }
  }
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "FZZJ";
  src: url("../../../assets/fonts/FZZJ-FXGXSJW.TTF");
}
.picture-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  .display-bg {
    width: 100%;
    height: 534px;
  }
  .container {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 78px auto 120px;
  }
  .keyword {
    margin: 20px 0 30px 0;
    span {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #ffffff;
      margin: 0 15px;
      cursor: pointer;
      letter-spacing: 5px;
    }
  }
  .recommend {
    width: 100%;
    .block-1 {
      display: flex;
      .outside-box {
        width: 50%;
      }
      .grid-content-first {
        .grid-content {
          padding: 0;
        }
      }
      .box-right {
        display: flex;
        flex-wrap: wrap;
        padding-left: 7px;
        .grid-content {
          &:nth-child(2),
          &:nth-child(4) {
            margin-left: 14px;
          }
        }
      }
    }
    .title {
      margin: 66px 0 40px;
      justify-content: space-between;
      display: flex;
      .left {
        align-items: center;
        display: flex;
        i {
          width: 6px;
          height: 20px;
          background: #e60021;
          border-radius: 3px;
        }
        .name {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #ffffff;
          margin-left: 16px;
        }
      }
      .more {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 100;
        color: #ffffff;
        position: relative;
        top: 32px;
        right: 0;
        cursor: pointer;
      }
    }
    .carousel-box {
      position: relative;
      .leftArrow,
      .rightArrow {
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        img {
          width: 66px;
          height: 90px;
        }
      }
      .leftArrow {
        left: 40px;
      }
      .rightArrow {
        right: 40px;
      }
      ::v-deep .el-carousel__container {
        height: 390px;
      }
      .periodical-image {
        height: 100%;
        flex: 1;
        position: relative;
        cursor: pointer;
        .image-title {
          position: absolute;
          bottom: 0;
          left: 50%;
          z-index: 9;
          font-family: "FZZJ";
          font-size: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          transform: translate(-50%, 0);
          .text {
            width: 25px;
            line-height: 20px;
            height: auto;
            word-wrap: break-word;
          }
          .line {
            width: 1px;
            height: 53px;
            background: #ffffff;
            margin-top: 7px;
          }
          .low-line {
            height: 30px;
            margin-top: 13px;
          }
        }
      }
    }
    .el-carousel__item {
      display: flex;
    }
    ::v-deep .el-carousel__indicators {
      display: none;
    }
    .grid-content {
      position: relative;
      margin-bottom: 14px;
      height: 176px;
      .sample-player-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 176px;
        overflow: hidden;
        z-index: 0;
        cursor: pointer;
        .video-player {
          .video {
            width: 100%;
          }
        }
        .info-block {
          z-index: 10;
          font-size: 14px;
          position: absolute;
          color: #999;
          background-color: #080808;
          padding: 2px 6px;
          border-radius: 3px;
          opacity: 0.8;
        }
        .right-top {
          right: 10px;
          top: 8px;
        }
        .left-top {
          left: 10px;
          top: 8px;
        }
      }
      .grid-img {
        height: 176px;
        width: 309px;
      }
      .grid-text {
        display: none;
      }
      .grid-oper {
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 47px;
        align-items: flex-end;
        padding: 0 22px 10px 12px;
        font-size: 14px;
        .name {
          width: 50%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        img {
          height: 17px;
          cursor: pointer;
          margin-left: 8px;
        }
        span {
          margin-left: 5px;
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
          position: relative;
          top: -3px;
        }
      }
      &:hover {
        .grid-text {
          display: block;
        }
      }
    }
    .grid-content-first {
      .grid-img {
        height: 364px;
        width: 633px;
      }
      .grid-content {
        height: 364px;
      }
    }
  }
}
// 媒体查询 大于等于某个宽度
@media screen and (min-width: 1080px) {
  .picture-display {
    .container {
      width: 1060px;
      .grid-content {
        .grid-img {
          height: 142px;
          width: 254px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 142px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 300px;
          width: 523px;
        }
      }
    }
  }
}
@media screen and (min-width: 1280px) {
  .picture-display {
    .container {
      width: 1060px;
      .grid-content {
        .grid-img {
          height: 142px;
          width: 254px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 142px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 300px;
          width: 523px;
        }
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .picture-display {
    .container {
      width: 1280px;
      .grid-content {
        .grid-img {
          height: 176px;
          width: 309px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 176px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 364px;
          width: 633px;
        }
      }
    }
  }
}
@media screen and (min-width: 1921px) {
  .picture-display {
    .container {
      width: 1280px;
      .grid-content {
        .grid-img {
          height: 176px;
          width: 309px;
        }
      }
      .box-right {
        .grid-content,
        .sample-player-box {
          height: 176px;
        }
      }
      .grid-content-first {
        .grid-img,
        .grid-content,
        .sample-player-box {
          height: 364px;
          width: 633px;
        }
      }
    }
  }
}
</style>
